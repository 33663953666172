import polyLine from './polyLine'
import map from './map'
import marker from './marker'
import polygon from './polygon'
import tooltip from './tooltip'

export default {
  marker,
  map,
  polyLine,
  polygon,
  tooltip
}
