const screenWidths = [
  { min: 0, max: 480, zoom: { global: 1, regional: 3 } },
  { min: 480, max: 1400, zoom: { global: 2, regional: 4 } },
  { min: 1400, zoom: { global: 3, regional: 5 } }
]

const getScreenZoom = () => {
  return screenWidths.find(
    ({ max, min }) =>
      !((min && window.innerWidth < min) || (max && window.innerWidth > max))
  ).zoom.global
}

const zoomLevels = screenWidths.find(
  ({ max, min }) =>
    !((min && window.innerWidth < min) || (max && window.innerWidth > max))
).zoom

export default {
  getScreenZoom: getScreenZoom,
  zoomLevels,
  zoom: zoomLevels.global,
  mapTypeControl: false,
  streetViewControl: false,
  disableDoubleClickZoom: true,
  scrollwheel: false,
  zoomControl: false,
  fullscreenControl: false,
  styles: [
    {
      elementType: 'labels',
      stylers: [{ visibility: 'off' }]
    },
    {
      featureType: 'administrative.country',
      elementType: 'labels',
      stylers: [{ visibility: 'on' }]
    },
    {
      featureType: 'landscape.natural',
      stylers: [{ visibility: 'off' }]
    },
    {
      featureType: 'administrative',
      elementType: 'geometry',
      stylers: [{ visibility: 'off' }]
    },
    {
      featureType: 'administrative.country',
      elementType: 'geometry.stroke',
      stylers: [{ visibility: 'on' }]
    },
    {
      featureType: 'administrative.province',
      elementType: 'geometry.stroke',
      stylers: [{ visibility: 'on' }]
    },
    {
      featureType: 'administrative.locality',
      elementType: 'geometry.stroke',
      stylers: [{ visibility: 'on' }]
    },
    {
      featureType: 'administrative.neighborhood',
      elementType: 'geometry.stroke',
      stylers: [{ visibility: 'on' }]
    },
    {
      featureType: 'administrative.land_parcel',
      elementType: 'geometry.stroke',
      stylers: [{ visibility: 'on' }]
    }
  ]
}
