// dashed line
const icon = {
  path: 'M 0,-1 0,1',
  strokeOpacity: 1,
  scale: 2
}

export default {
  strokeColor: '#344050',
  strokeOpacity: 0,
  icons: [{ icon, offset: '0', repeat: '10px' }]
}
