export default (title, content, footer) => {
  const tooltip = document.createElement('div')
  tooltip.innerHTML = `<div class="title-head">${title}</div>
    <span class="map-address">${content}</span>
    ${footer ? `<span class="arrival-date">${footer}</span>` : ''}`

  return `<div class="title-head">${title}</div>
    <span class="map-address">${content}</span>
    ${footer ? `<span class="arrival-date">${footer}</span>` : ''}`
}
