export default {
  fillColor: '#8CC7FF',
  fillOpacity: 0,
  strokeColor: '#4694DF',
  strokeWeight: '1',

  hover: {
    fillColor: '#8CC7FF',
    fillOpacity: 0.2,
    strokeColor: '#4694DF',
    strokeWeight: '2'
  },

  focus: {
    fillColor: '#8CC7FF',
    fillOpacity: 0.2,
    strokeColor: '#4694DF',
    strokeWeight: '2'
  }
}
