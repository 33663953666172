export default {
  icons: {
    yellow: {
      url: '/markers/yellow-circle.png',
      anchor: { x: 15, y: 15 }
    },
    blue: {
      url: `/markers/blue-circle.png`,
      anchor: { x: 15, y: 15 }
    },
    pointer: {
      url: `/markers/data-point.png`
    }
  }
}
